var y = {
    fieldSeparator: ",",
    decimalSeparator: ".",
    quoteStrings: !0,
    quoteCharacter: '"',
    showTitle: !1,
    title: "My Generated Report",
    filename: "generated",
    showColumnHeaders: !0,
    useTextFile: !1,
    useBom: !0,
    columnHeaders: [],
    useKeysAsHeaders: !1,
    boolDisplay: {
      true: "TRUE",
      false: "FALSE"
    },
    replaceUndefinedWith: ""
  },
  L = "\r\n",
  F = "\uFEFF",
  q = $ => Object.assign({}, y, $);
class Q extends Error {
  constructor($) {
    super($);
    this.name = "CsvGenerationError";
  }
}
class T extends Error {
  constructor($) {
    super($);
    this.name = "EmptyHeadersError";
  }
}
class U extends Error {
  constructor($) {
    super($);
    this.name = "CsvDownloadEnvironmentError";
  }
}
class V extends Error {
  constructor($) {
    super($);
    this.name = "UnsupportedDataFormatError";
  }
}
var X = $ => $,
  z = $ => $,
  Z = X,
  _ = X,
  Y = X,
  K = X,
  N = X;
var r = function ($, x) {
    if (x == '"' && $.indexOf('"') > -1) return $.replace(/"/g, '""');
    return $;
  },
  D = $ => typeof $ === "object" ? K($.key) : K($),
  h = $ => typeof $ === "object" ? N($.displayLabel) : N($),
  P = ($, ...x) => x.reduce((W, j) => j(W), $),
  S = $ => x => $.useBom ? _(z(x) + F) : x,
  R = $ => x => $.showTitle ? B(_(z(x) + $.title))(Y("")) : x,
  B = $ => x => _(z($) + z(x) + L),
  E = $ => (x, W) => w($)(Y(z(x) + z(W))),
  w = $ => x => X(z(x) + $.fieldSeparator),
  O = ($, x) => W => {
    if (!$.showColumnHeaders) return W;
    if (x.length < 1) throw new T("Option to show headers but none supplied. Make sure there are keys in your collection or that you've supplied headers through the config options.");
    let j = Y("");
    for (let A = 0; A < x.length; A++) {
      const J = h(x[A]);
      j = E($)(j, b($, z(J)));
    }
    return j = Y(z(j).slice(0, -1)), B(W)(j);
  },
  C = ($, x, W) => j => {
    let A = j;
    for (var J = 0; J < W.length; J++) {
      let I = Y("");
      for (let M = 0; M < x.length; M++) {
        const H = D(x[M]),
          v = W[J][z(H)];
        I = E($)(I, b($, v));
      }
      I = Y(z(I).slice(0, -1)), A = B(A)(I);
    }
    return A;
  },
  l = z,
  k = $ => +$ === $ && (!isFinite($) || Boolean($ % 1)),
  m = ($, x) => {
    if (k(x)) {
      if ($.decimalSeparator === "locale") return Z(x.toLocaleString());
      if ($.decimalSeparator) return Z(x.toString().replace(".", $.decimalSeparator));
    }
    return Z(x.toString());
  },
  G = ($, x) => {
    let W = x;
    if ($.quoteStrings || $.fieldSeparator && x.indexOf($.fieldSeparator) > -1 || $.quoteCharacter && x.indexOf($.quoteCharacter) > -1 || x.indexOf("\n") > -1 || x.indexOf("\r") > -1) W = $.quoteCharacter + r(x, $.quoteCharacter) + $.quoteCharacter;
    return Z(W);
  },
  p = ($, x) => {
    const W = x ? "true" : "false";
    return Z($.boolDisplay[W]);
  },
  g = ($, x) => {
    if (typeof x === "undefined" && $.replaceUndefinedWith !== void 0) return G($, $.replaceUndefinedWith + "");
    if (x === null) return G($, "null");
    return G($, "");
  },
  b = ($, x) => {
    if (typeof x === "number") return m($, x);
    if (typeof x === "string") return G($, x);
    if (typeof x === "boolean" && $.boolDisplay) return p($, x);
    if (x === null || typeof x === "undefined") return g($, x);
    throw new V(`
    typeof ${typeof x} isn't supported. Only number, string, boolean, null and undefined are supported.
    Please convert the data in your object to one of those before generating the CSV.
    `);
  };
var Q$ = $ => x => {
    const W = q($),
      j = W.useKeysAsHeaders ? Object.keys(x[0]) : W.columnHeaders;
    let A = P(_(""), S(W), R(W), O(W, j), C(W, j, x));
    if (z(A).length < 1) throw new Q("Output is empty. Is your data formatted correctly?");
    return A;
  },
  s = $ => x => {
    const W = q($),
      j = z(x),
      A = W.useTextFile ? "plain" : "csv";
    return new Blob([j], {
      type: `text/${A};charset=utf8;`
    });
  },
  T$ = $ => x => {
    if (!window) throw new U("Downloading only supported in a browser environment.");
    const W = s($)(x),
      j = q($),
      A = j.useTextFile ? "txt" : "csv",
      J = `${j.filename}.${A}`,
      I = document.createElement("a");
    I.download = J, I.href = URL.createObjectURL(W), I.setAttribute("visibility", "hidden"), document.body.appendChild(I), I.click(), document.body.removeChild(I);
  };
export { q as mkConfig, Q$ as generateCsv, T$ as download, l as asString, s as asBlob };